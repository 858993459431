<template>
  <div class="pt-16">
    <div
      class="w-full h-full bg-fixed bg-contain md:bg-center bg-no-repeat"
      :style="`background-image: url(${bgImage})`"
    >
      <div
        class="w-full h-75vh md:h-50vh lg:h-75vh py-12 px-4 md:px-0 flex items-center"
      >
        <p
          class="w-full m-auto p-4 text-xl text-pulpBlue text-center font-semibold bg-softGrey80"
        >
          Improve your English skills and apply for the international nursing
          job you dream of​. <br />
          If your English skills are the only thing stopping you from qualifying
          for nursing jobs in the US or Canada, neo is right for you!​
        </p>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Personalized Study Path​
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              Once you know your current English CEFR level, you can count the
              levels you need to reach a CEFR B2 and above. neo’s AI will create
              a personalized English-speaking journey that will help you reach
              your goal in the most effective and affordable way possible. ​
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pt-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Nexgen Nursing Course​
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              neo+ includes 11 CEFR levels. Each level takes about 36 study
              hours to complete. You also meet with your neo coach for
              personalized practice during your neoLive 1:1 coaching sessions.
              All you need is 20 minutes per session with coaches who speak your
              nursing language to improve your fluency.​
            </p>
          </div>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <AboutNeoCourses> </AboutNeoCourses>
      </div>
      <div class="bg-white py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-52 lg:h-72"
                src="@/assets/images/icons/12.-nurse-with-cert.jpg"
                alt="12.-nurse-with-cert.jpg"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  CEFR-Aligned Certification​
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  Once you complete every CEFR level’s requirements, you are
                  prompted to take the Certification Test. If you pass, you will
                  earn an internationally accepted English certificate that is
                  fully aligned to the Common European Framework of Reference
                  for Languages (CEFR). You can also share these accomplishments
                  with potential employers easily with our
                  <router-link class="text-secondary" to="/studentcertificateverification"
                    >Cert-verify page</router-link
                  >.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ul class="mb-20 flex justify-center">
      <li
        class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
      >
        Download more info
      </li>
    </ul> -->
  </div>
</template>

<script>
import AboutNeoCourses from "../components/AboutNeoCourses.vue";

export default {
  components: {
    AboutNeoCourses,
  },
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/11.nurses.jpg"),
      isMobile: window.innerWidth <= 768,
      mobileImageUrl: require("@/assets/images/mobile/11.nurses-720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/11.nurses.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>